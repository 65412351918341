import React from "react";
import "./contact.css";
import contactImg from "../../../assets/images/contact.png";

class ContactComponent extends React.Component {
  render() {
    return (
      <>
        <section className="contactSection" id="contact">
          <div
            className="sectionHead"
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <span>SAY HELLO</span>
            <h2>CONTACT</h2>
          </div>

          <div className="contactContainer">
            <img
              src={contactImg}
              alt=""
              data-aos="zoom-in"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="1000"
            />
            <div className="contactTypes">
              <div
                className="flexContainer"
                data-aos="slide-right"
                data-aos-offset="10"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <div className="typeBox">
                  <i className="lni lni-envelope"></i>
                  <a href="mailto:puneettaylorpt.pt@gmail.com">
                    puneettaylorpt.pt@gmail.com
                  </a>
                  <p>Email Me</p>
                </div>
              </div>
              <div
                className="flexContainer"
                data-aos="slide-left"
                data-aos-offset="10"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <div className="typeBox">
                  <i className="lni lni-phone"></i>
                  <a
                    href="tel:+919024616807"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    +91 90246 16807
                  </a>
                  <p>Call Me</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default ContactComponent;
