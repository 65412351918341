import React from "react";
import "./work.css";
import indesesLogo from "../../../assets/images/Indeses_Logo.png";
import indxxLogo from "../../../assets/images/INDXX_Logo.png";
import bfsLogo from "../../../assets/images/Bafflesol_Logo.png";
import thirdEyeAILogo from "../../../assets/images/ThirdEyeLogo.png";
import shopPokeLogo from "../../../assets/images/ShopPoke_Logo.png";

class WorkComponent extends React.Component {
  render() {
    return (
      <>
        <section className="worksSection" id="projects">
          <div
            className="sectionHead"
            data-aos="slide-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <span>LOOK AT MY WORK</span>
            <h2>EXPERIENCE</h2>
          </div>

          <div className="worksContainer">
            <article className="workBox alt">
              <div className="workImageContainer">
                <div className="workImageCell">
                  <div className="workImage">
                    <img src={indxxLogo} alt="Stonk." />
                  </div>
                </div>
              </div>
              <div
                className="workDescribeContainer"
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <h3
                  className="workDuration"
                  data-aos="fade-left"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                  data-aos-delay="1000"
                >
                  May 2022 to Present
                </h3>
                <h3>Indxx Capital Management Pvt Ltd, Gurugram </h3>
                <h4>
                  <i>(Architect / Vice Precident)</i>
                </h4>
                <br />
                <h4>Responsibilities:</h4>
                <ul>
                  <li>Requirement analysis with the clients/team.</li>
                  <li>Web maintenance of quontigo.com</li>
                  <li>UI integration with JAVA Liferay and AUI.</li>
                  <li>Portlet development using Apache Maven in JSP.</li>
                  <li>UI Designs & implemantation for STOXX & DAX platform</li>
                  <li>STOXX & DAX Migration</li>
                  <li>Cross Browser compatability</li>
                  <li>Validation implementation using JS/jQuery</li>
                  <li>File Monitoring Dashboard using React JS</li>
                  <li>UI Development for Data Platform with Vue JS</li>
                  <li>Unit testing</li>
                </ul>
                <br />
                <h4>Skills Used: </h4>
                <h4>
                  <span className="techStack">Adobe XD</span>{" "}
                  <span className="techStack">Adobe Photoshop</span>{" "}
                  <span className="techStack">HTML</span>{" "}
                  <span className="techStack">CSS</span>{" "}
                  <span className="techStack">Javascript</span>{" "}
                  <span className="techStack">Bootstrap</span>{" "}
                  <span className="techStack">jQuery</span>{" "}
                  <span className="techStack">Maven</span>{" "}
                  <span className="techStack">Liferay</span>{" "}
                  <span className="techStack">React</span>{" "}
                  <span className="techStack">Vue</span>{" "}
                  <span className="techStack">REST API</span>{" "}
                  <span className="techStack">JSON</span>{" "}
                  <span className="techStack">AJAX</span>{" "}
                </h4>
              </div>
            </article>
            <article className="workBox">
              <div className="workImageContainer">
                <div className="workImageCell">
                  <div className="workImage">
                    <img src={thirdEyeAILogo} alt="Stonk." />
                  </div>
                </div>
              </div>
              <div
                className="workDescribeContainer"
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <h3
                  className="workDuration"
                  data-aos="fade-right"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                  data-aos-delay="1000"
                >
                  Feb 2021 to May 2022
                </h3>
                <h3>ThirdEye AI Pvt Ltd (JBM Group), Gurugram </h3>
                <h4>
                  <i>(UI/UX Engineer)</i>
                </h4>
                <br />
                <h4>Responsibilities:</h4>
                <ul>
                  <li>Requirement analysis with the clients/team.</li>
                  <li>Industry 4.0 UI Development with React</li>
                  <li>Manpower Monitoring System</li>
                  <li>Machine Monitoring System</li>
                  <li>Energy Management System</li>
                  <li>Auto Production Planning System</li>
                  <li>Face Recognition Mobile App Design</li>
                  <li>Graphics & Motion Designing</li>
                  <li>Unit testing</li>
                </ul>
                <br />
                <h4>Skills Used: </h4>
                <h4>
                  <span className="techStack">Adobe XD</span>{" "}
                  <span className="techStack">Adobe AfeterEffects</span>{" "}
                  <span className="techStack">HTML</span>{" "}
                  <span className="techStack">CSS</span>{" "}
                  <span className="techStack">Javascript</span>{" "}
                  <span className="techStack">Bootstrap</span>{" "}
                  <span className="techStack">jQuery</span>{" "}
                  <span className="techStack">React</span>{" "}
                  <span className="techStack">REST API</span>{" "}
                  <span className="techStack">JSON</span>{" "}
                  <span className="techStack">AJAX</span>{" "}
                  <span className="techStack">HighCharts</span>
                </h4>
              </div>
            </article>
            <article className="workBox alt">
              <div className="workImageContainer">
                <div className="workImageCell">
                  <div className="workImage">
                    <img src={bfsLogo} alt="Stonk." />
                  </div>
                </div>
              </div>
              <div
                className="workDescribeContainer"
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <h3
                  className="workDuration"
                  data-aos="fade-left"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                  data-aos-delay="1000"
                >
                  Jan 2019 to Oct 2020
                </h3>
                <h3>Bafflesol Technologies Pvt Ltd, Gurugram </h3>
                <h4>
                  <i>(Sr UI Consultant)</i>
                </h4>
                <br />
                <h4>Responsibilities:</h4>
                <ul>
                  <li>Requirement analysis with the clients/team.</li>
                  <li>Bafflesol and Bafflesol Academy Website</li>
                  <li>Outfulence Web and Mobile App Designs</li>
                  <li>Travel on Street Website Design</li>
                  <li>CRM Design for Minda & EVS</li>
                  <li>ED&F MAN Report and invoice templates Designs</li>
                  <li>Unit testing</li>
                </ul>
                <br />
                <h4>Skills Used: </h4>
                <h4>
                  <span className="techStack">Adobe XD</span>{" "}
                  <span className="techStack">Adobe Photoshop</span>{" "}
                  <span className="techStack">HTML</span>{" "}
                  <span className="techStack">CSS</span>{" "}
                  <span className="techStack">Javascript</span>{" "}
                  <span className="techStack">Bootstrap</span>{" "}
                  <span className="techStack">jQuery</span>{" "}
                </h4>
              </div>
            </article>
            <article className="workBox">
              <div className="workImageContainer">
                <div className="workImageCell">
                  <div className="workImage">
                    <img src={indesesLogo} alt="Stonk." />
                  </div>
                </div>
              </div>
              <div
                className="workDescribeContainer"
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <h3
                  className="workDuration"
                  data-aos="fade-right"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                  data-aos-delay="1000"
                >
                  Mar 2015 to Oct 2018
                </h3>
                <h3>Indeses Business Ventures Pvt Ltd, Jaipur </h3>
                <h4>
                  <i>(Sr Software Engineer)</i>
                </h4>
                <br />
                <h4>Responsibilities:</h4>
                <ul>
                  <li>Requirement analysis with the clients/team.</li>
                  <li>School Management Web App using PHP & MySQL.</li>
                  <li>Many Websites Developed using Wordpress.</li>
                  <li>Website Management using cPanel.</li>
                  <li>Graphics Designing using Photoshop.</li>
                  <li>Unit Testing</li>
                </ul>
                <br />
                <h4>Skills Used: </h4>
                <h4>
                  <span className="techStack">Adobe Photoshop</span>{" "}
                  <span className="techStack">HTML</span>{" "}
                  <span className="techStack">CSS</span>{" "}
                  <span className="techStack">Javascript</span>{" "}
                  <span className="techStack">Bootstrap</span>{" "}
                  <span className="techStack">jQuery</span>{" "}
                  <span className="techStack">PHP</span>{" "}
                  <span className="techStack">MySQL</span>{" "}
                  <span className="techStack">JSON</span>{" "}
                  <span className="techStack">AJAX</span>{" "}
                </h4>
              </div>
            </article>
            <article className="workBox alt">
              <div className="workImageContainer">
                <div className="workImageCell">
                  <div className="workImage">
                    <img src={shopPokeLogo} alt="Stonk." />
                  </div>
                </div>
              </div>
              <div
                className="workDescribeContainer"
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <h3
                  className="workDuration"
                  data-aos="fade-left"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="1000"
                  data-aos-delay="1000"
                >
                  April 2014 to Sep 2014
                </h3>
                <h3>ShopPoke Technologies Services Pvt Ltd, Banglore </h3>
                <h4>
                  <i>(Graphics & Web Designer)</i>
                </h4>
                <br />
                <h4>Resposibilities:</h4>
                <ul>
                  <li>Mobile App Design for ShopPoke App.</li>
                  <li>Web Design for ShopPoke Website.</li>
                  <li>Icon & Graphics Designs using Photoshop.</li>
                  <li>Unit testing</li>
                </ul>
                <br />
                <h4>Skills Used: </h4>
                <h4>
                  <span className="techStack">HTML</span>{" "}
                  <span className="techStack">CSS</span>{" "}
                  <span className="techStack">Javascript</span>{" "}
                  <span className="techStack">jQuery</span>{" "}
                  <span className="techStack">Photoshop</span>{" "}
                </h4>
              </div>
            </article>
          </div>
        </section>
      </>
    );
  }
}
export default WorkComponent;
