import React from "react";
import "./skills.css";
import skillHtml from "../../../assets/images/skills/html.svg";
import skillCss from "../../../assets/images/skills/css.svg";
import skillApi from "../../../assets/images/skills/api.svg";
import skillDns from "../../../assets/images/skills/dns.svg";
import skillCp from "../../../assets/images/skills/cPanel.png";
import skillJira from "../../../assets/images/skills/Jira.png";
import skillGit from "../../../assets/images/skills/git.svg";
import skillGitHub from "../../../assets/images/skills/github.svg";
import skillJS from "../../../assets/images/skills/javascript.svg";
import skillJquery from "../../../assets/images/skills/jquery.svg";
import skillBs from "../../../assets/images/skills/bootstrap.png";
import skillThree from "../../../assets/images/skills/three.png";
import skillVue from "../../../assets/images/skills/vue.png";
import skillNg from "../../../assets/images/skills/angular.png";
import skillMS from "../../../assets/images/skills/microsoft.svg";
import skillLin from "../../../assets/images/skills/linux.png";
import skillMWin from "../../../assets/images/skills/microsoftWindows.svg";
import skillReact from "../../../assets/images/skills/react.svg";
import skillSass from "../../../assets/images/skills/sass.svg";
import skillVSCode from "../../../assets/images/skills/vscode.svg";
import skillPS from "../../../assets/images/skills/ps.svg";
import skillXD from "../../../assets/images/skills/xd.svg";
import skillae from "../../../assets/images/skills/ae.svg";
import skillpr from "../../../assets/images/skills/premiere.png";
import skillPhp from "../../../assets/images/skills/php.svg";
import skillMySql from "../../../assets/images/skills/mysql.svg";
import skillWp from "../../../assets/images/skills/wordpress.svg";
import skillNode from "../../../assets/images/skills/node.svg";
class SkillsComponent extends React.Component {
  render() {
    return (
      <>
        <section className="skillsSection" id="skills">
          <div
            className="sectionHead"
            data-aos="slide-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <span>CHECK OUT MY</span>
            <h2>SKILLS</h2>
          </div>

          <div
            className="skillsContainer"
            data-aos="zoom-in"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1000"
          >
            <ul className="skillsGrid">
              <li>
                <div className="itemContainer">
                  <img src={skillMWin} alt="Microsoft Windows" />
                  <p>Windows</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillMS} alt="Microsoft 365" />
                  <p>M365</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillLin} alt="Linux" />
                  <p>Linux</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillPS} alt="Photoshop, Adobe Photoshop" />
                  <p>Adobe Ps</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillXD} alt="Adobe XD, XD" />
                  <p>Adobe Xd</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillae} alt="Adobe After Effects, AE" />
                  <p>Adobe Ae</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillpr} alt="Adobe Premiere Pro" />
                  <p>Adobe Pr</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillHtml} alt="HTML, HTML5" />
                  <p>HTML</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillCss} alt="CSS, CSS3" />
                  <p>CSS</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillSass} alt="sass" />
                  <p>SASS</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillJS} alt="Javascript, JS" />
                  <p>Javascript</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillJquery} alt="jQuery" />
                  <p>JQuery</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillBs} alt="Twitter Bootstrap, Bootstrap" />
                  <p>Bootstrap</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillThree} alt="Three JS, three.js, three" />
                  <p>THREE JS</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillNg} alt="Angular, angular.js, Angular JS" />
                  <p>Angular</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillVue} alt="Vue, vue.js, Vue JS" />
                  <p>Vue</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillReact} alt="React, react.js, React JS" />
                  <p>React</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillNode} alt="Node, node.js, Node JS" />
                  <p>Node</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillPhp} alt="PHP" />
                  <p>PHP</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillMySql} alt="MySQL" />
                  <p>MySQL</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillWp} alt="Wordpress" />
                  <p>Wordpress</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillApi} alt="Rest API" />
                  <p>REST API</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillGit} alt="Git" />
                  <p>Git</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillGitHub} alt="Git Hub" />
                  <p>GitHub</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillVSCode} alt="VS Code" />
                  <p>VS Code</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillJira} alt="Jira" />
                  <p>Jira</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillDns} alt="DNS" />
                  <p>DNS</p>
                </div>
              </li>
              <li>
                <div className="itemContainer">
                  <img src={skillCp} alt="cPanel" />
                  <p>cPanel</p>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </>
    );
  }
}
export default SkillsComponent;
