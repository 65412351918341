import React from "react";
import dp from "../../../assets/images/pt.png";
import resume from "../../../assets/PuneetTailor.pdf";
import "./header.css";

class MainHeader extends React.Component {
  render() {
    return (
      <>
        <header>
          <div
            className="heroImageContainer"
            data-aos="slide-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1000"
          >
            <img src={dp} alt="Puneet Tailor" />
          </div>
          <div
            className="heroDescription"
            data-aos="slide-left"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1000"
          >
            <p className="greeting">Hello! I'm</p>
            <h1>
              <span>Puneet</span> Tailor
            </h1>
            <p className="smallBio">
              A <span>UI/UX Architect</span> passionate in crafting seamless
              digital experiences & elevating user engagement.
            </p>
            <div className="socialsResume">
              <a
                className="resumeLink"
                href={resume}
                alt="Puneet Tailor's resume"
                rel="noopener noreferrer"
                data-aos="slide-left"
                data-aos-offset="10"
                data-aos-easing="ease-in-sine"
                data-aos-duration="2000"
                download
              >
                Resume
              </a>
              <ul
                className="socialsList"
                data-aos="slide-left"
                data-aos-offset="10"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1500"
              >
                <li>
                  <a
                    href="https://www.linkedin.com/in/puneet-tailor/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="lni lni-linkedin-original"
                      aria-label="Link to Puneet's LinkedIn page."
                    ></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://github.com/puneet6789"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="lni lni-github-original"
                      aria-label="Link to Puneet's GitHub page."
                    ></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/puneettaylor"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="lni lni-twitter-original"
                      aria-label="Link to Puneet's Twitter profile."
                    ></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default MainHeader;
