import React from "react";
import "./mainNav.css";

class MainNav extends React.Component {
  render() {
    return (
      <>
        <div id="home" className="visuallyHidden"></div>
        <div className="menuButton" id="menuButton" tabIndex="0">
          <div className="linesContainer">
            <span></span>
          </div>
        </div>
      </>
    );
  }
}

export default MainNav;
