import React from "react";
import MainHeader from "./header/headerComponent.js";
import AboutComponent from "./about/aboutComponent.js";
import SkillsComponent from "./skills/skillsComponent.js";
import WorkComponent from "./work/workComponent.js";
import ContactComponent from "./contact/contactComponent.js";

class MainContent extends React.Component {
  render() {
    return (
      <>
        <div className="outerWrapper">
          <div className="innerWrapper">
            <MainHeader />
            <main>
              <AboutComponent />
              <SkillsComponent />
              <WorkComponent />
              <ContactComponent />
            </main>
          </div>
          <footer>
            <p>
              Puneet Tailor in <i className="lni lni-heart-filled"></i> with
              Make in India.
            </p>
          </footer>
        </div>
      </>
    );
  }
}

export default MainContent;
