import React from "react";
import "./App.css";
import "./LineIcons.css";
import MainNav from "./components/mainNav/mainNav.js";
import SideBar from "./components/sideBar/sideBar.js";
import MainContent from "./components/mainContent/mainContent.js";
import StarsCanvas from "./components/mainContent/stars/stars.js";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <MainNav></MainNav>
      <SideBar></SideBar>
      <MainContent></MainContent>
      <StarsCanvas></StarsCanvas>
    </>
  );
}

export default App;
