import React from "react";
import "./sideBar.css";
import mainLogo from "../../assets/images/logo.png";

class SideBar extends React.Component {
  render() {
    return (
      <>
        <aside className="sideMenu sideMenuShow" id="sideMenu">
          <p className="logo">
            <img src={mainLogo} alt="Logo" />
          </p>
          <nav>
            <ul className="navBar">
              <li className="homeNav">
                <a href="#home" className="navItem">
                  <i className="lni lni-home"></i>
                  <span className="navItemName">HOME</span>
                </a>
              </li>
              <li className="aboutNav">
                <a href="#about" className="navItem">
                  <i className="lni lni-user"></i>
                  <span className="navItemName">ABOUT</span>
                </a>
              </li>
              <li className="skillsNav">
                <a href="#skills" className="navItem">
                  <i className="lni lni-code-alt"></i>
                  <span className="navItemName">SKILLS</span>
                </a>
              </li>
              <li className="worksNav">
                <a href="#projects" className="navItem">
                  <i className="lni lni-briefcase"></i>
                  <span className="navItemName">EXPERIENCE</span>
                </a>
              </li>
              <li className="contactNav">
                <a href="#contact" className="navItem">
                  <i className="lni lni-phone"></i>
                  <span className="navItemName">CONTACT</span>
                </a>
              </li>
            </ul>
          </nav>
          <div className="scrollDown" id="scrollDown">
            <i className="lni lni-arrow-down"></i>
            <span className="navItemName scrollDownText">Scroll Down</span>
          </div>
        </aside>
      </>
    );
  }
}

export default SideBar;
