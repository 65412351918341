import React from "react";
import "./about.css";
class AboutComponent extends React.Component {
  render() {
    return (
      <>
        <section className="aboutSection" id="about">
          <div
            className="sectionHead"
            data-aos="slide-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <span>SOME INFO</span>
            <h2>ABOUT ME</h2>
          </div>

          <article
            className="aboutContainer sectionContainer"
            data-aos="zoom-in"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1000"
          >
            <p>
              An acknowledged UX/UI Architect with expertise in UX Prompt Design
              and DesignOps. Passionate about creating exceptional user
              experiences (UX) and intuitive user interfaces (UI) for
              software-as-a-service (SaaS) products.
            </p>
            <p>I specialize in</p>
            <ul>
              <li>Data visualization and Insight Analysis</li>
              <li>User Experience (UX) and User Interface (UI)</li>
              <li>Mobile, Web, Cross-platform UX</li>
              <li>Responsive Web Designing</li>
            </ul>
            <p>
              Proficient in wireframing, prototyping, and designing in tools
              such as Adobe Xd, Figma, Photoshop and other industry-standard
              software. Committed to delivering innovative and user-centered
              designs that drive product success. Strong understanding of design
              principles, information architecture, and interaction design.
              Experienced in collaborating with cross-functional teams and
              stakeholders to translate business requirements into impactful
              design solutions.
            </p>
            <p>
              Over the years, I have built brands, businesses, and relationships
              that I cherish and hold close to my heart!
            </p>
            <p>
              If you're looking for an Front End Architect who can help you
              create a winning B2B SaaS product, I'd love to connect. Let's
              build something great together!
            </p>
          </article>
        </section>
      </>
    );
  }
}

export default AboutComponent;
